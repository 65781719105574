/* You can add global styles to this file, and also import other style files */

@import './assets/scss/variables';

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}